<template>
  <router-view />

  <Preloader class="preloader-general" :showHide="isShowGeneralPreloader" />

</template>

<script>

import Preloader from "@/components/Preloader.vue";

export default {
	name: "App",
	components: {
		Preloader
	},
	watch: {
		$route: {
			immediate: true,
			handler: (to, from) => {
				document.title = to.meta.title || "Victoriya Date";
			},
		},
	},
	  provide() {
    return {
      provideShowPreloader: this.showPreloader,
      provideHidePreloader: this.hidePreloader
    };
  },
  data() {
    return {
      isShowGeneralPreloader: false
    };
  },
  methods: {
    showPreloader() {
      this.isShowGeneralPreloader = true;
    },
    hidePreloader() {
      this.isShowGeneralPreloader = false;
    }
  }
};
</script>

<style lang="scss">
	.preloader-general {
		position: fixed;
		z-index: 999999;
		top: 0;
		left: 0;
		right: auto;
		bottom: auto;
		height: 100%;
		width: 100%;
		background-image: -webkit-linear-gradient(left, #0674fd, #00d0f9);
	}
</style>
