<template>

  <transition name="fade">
    <div class="modal-wrapper" v-if="this.isshow" :class="{ open: this.isshow }" @click="onClose">

      <div class="modal-container">
      
        <div class="modal-content">
          
          <div class="modal" @click.stop>

            <button type="button" class="modal-btn-close" @click="onClose">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M19.596 1.81822L1.81827 19.596L0.404053 18.1818L18.1818 0.404007L19.596 1.81822Z" />
								<path fill-rule="evenodd" clip-rule="evenodd" d="M0.403954 1.81822L18.1817 19.596L19.5959 18.1818L1.81817 0.404007L0.403954 1.81822Z" />
							</svg>
            </button>
            
            <slot name="content">

            </slot>        

          </div>
      
        </div>
      
      </div>

    </div>
  </transition>
    
</template>

<script>
import src from '../controllers/components/ModalCtrl';
export default src;
</script>