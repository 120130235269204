
export default {
	name: 'IModal',
  props: {
		show: {
			type: Boolean,
      default: false
    }
  },
	data() {
		return {
			windowBefore: 0,
      open: true,
			isshow: false,
    }
  },
  emits:{
    show: null,
    close: null
  },
  watch: {
		show: {
			immediate: true,
      handler (newVal, oldVal) {
				if(newVal!=oldVal) {
          this.isshow = newVal;

          if(this.isshow == true) {
            document.querySelector('html').classList.add('overflow-hidden');
          }
        }
      }
    }
  },
  methods: {
		onClose() {
			this.isshow = false;
      this.$emit('close');

      if (document.querySelectorAll('.modal').length >=2) {
        return false;
      }

      document.querySelector('html').classList.remove('overflow-hidden');
    },
    onShow() {

      this.isshow = true;
      this.$emit('show');

      document.querySelector('html').classList.add('overflow-hidden');
    },
  },
}