
export default {
	name: 'ICard',
	props: {
		cardImg: {
			type: String,
			default: null,
			required: true
		},
		cardName: {
			type: String,
			default: null,
		},
		cardAge: {
			type: Number,
			default: null,
		},
		cardCountry: {
			type: String,
			default: null,
		},
		cardAuthor: {
			type: String,
			default: null,
		},
		cardArticle: {
			type: String,
			default: null,
		},
		cardStatus: {
			type: Boolean,
			default: true,
		},
		cardArray: {
			type: Array,
			default: () => []
		}
  },
	methods:{
	},
	data() {
    return {
			isActive: false,
    }
  },
}