
export default {
	name: 'ICardTestimonial',
	props: {
		cardTestimonialImg: {
			type: String,
			default: null,
		},
		cardTestimonialAuthor: {
			type: String,
			default: null,
		},
		cardTestimonialblockquote: {
			type: String,
			default: null,
		},
		cardTestimonialDate: {
			type: String,
			default: null,
		},
		cardTestimonialDateAttr: {
			type: String,
			default: null,
		}
  },
}