import { createApp, } from 'vue';
import App from '@/App.vue';

import router from '@/router';

// plugins
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

// static
import '@/assets/styles/footer.scss';

import '@/assets/styles/card.scss';

import '@/assets/styles/card-slider.scss';

import '@/assets/styles/card-testimonials.scss';

import '@/assets/styles/card-testimonials-slider.scss'

import '@/assets/styles/blockquote-icon.scss';

import '@/assets/styles/modal.scss';
import '@/assets/styles/modal-registration.scss';
import '@/assets/styles/modal-agreement.scss';

import '@/assets/styles/page-error.scss';

import '@/assets/styles/main.scss';
import '@/assets/styles/breakpoints.scss';

// Custom packages

import ICard from '@/components/Card.vue';

import ICardTestimonial from '@/components/CardTestimonials.vue';

import IModal from '@/components/Modal.vue';

import IFooter from '@/components/Footer.vue';

import IBlockquoteIcon from '@/components/BlockquoteIcon.vue';

// plugins
import VueSplide from '@splidejs/vue-splide';


const app = createApp(App);

// packages
app.component('IFooter', IFooter);

app.component('ICard', ICard);

app.component('ICardTestimonial', ICardTestimonial);

app.component('IModal', IModal);

app.component('IBlockquoteIcon', IBlockquoteIcon);

// plugins
app.use(VueSplide);

app.use(router);

app.mount('#app');
