 
export default {
	name: 'IBlockquoteIcon',
  props: {
		icon: {
			type: String,
      default: ''
    },
		title: {
			type: String,
      default: ''
    },
		text: {
			type: String,
      default: ''
    }
  },
}