<template>

	<figure class="card-testimonial">

		<img src="@/assets/images/icons/quotes.svg" alt="quotes" class="card-testimonial-quotes">

		<img :src="cardTestimonialImg" :alt="cardTestimonialImg" class="card-testimonial-img" v-if="cardTestimonialImg">
		<div v-else>image not found</div>

		<figcaption>

			<blockquote class="card-testimonial-blockquote" v-if="cardTestimonialblockquote">
				<p>
					{{ cardTestimonialblockquote }}
				</p>
			</blockquote>

			<div class="card-testimonial-footer" v-if="cardTestimonialAuthor || cardTestimonialDate">
				<div class="card-testimonial-author">{{ cardTestimonialAuthor }}</div>

				<time class="card-testimonial-date" :datetime="cardTestimonialDateAttr" v-if="cardTestimonialDate && cardTestimonialDateAttr">{{ cardTestimonialDate }}</time>
			</div>

		</figcaption>

	</figure>

</template>

<script>
import src from "../controllers/components/CardTestimonialsCtrl";
export default src;
</script>