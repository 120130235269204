<template>

	<figure class="card">

		<img :src="cardImg" :alt="cardImg" class="card-img" v-if="cardImg">
		<div v-else>image not found</div>

		<figcaption v-if="cardName || cardAge || cardStatus">

			<ul class="card-list">

				<li class="card-name" v-if="cardName">
					{{ cardName }}
				</li>

				<li v-if="cardAge" class="card-age">
					{{ cardAge }} years
				</li>

				<li v-if="cardCountry" class="card-сountry">
					<img src="@/assets/images/icons/marker.svg" alt="marker">
					{{ cardCountry }}
				</li>

			</ul>

			<article class="card-article">

				<h3 class="card-article-author">
					{{ cardAuthor }}
				</h3>

				<p>
					{{ cardArticle }}
				</p>

			</article>

			<span class="card-status" v-if="cardStatus">Online now</span>

		</figcaption>

	</figure>

</template>

<script>
import src from "../controllers/components/CardCtrl";
export default src;
</script>