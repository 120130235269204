<template>
	<blockquote class="blockquote-icon-box">

		<div class="blockquote-icon">
			<slot name="icon">
				{{ icon }}
			</slot>
		</div>

		<div class="blockquote-icon-inner">
			<div class="blockquote-icon-title">
				<slot name="title">
						{{ title }}
				</slot>
			</div>
			<p class="blockquote-icon-txt">
				<slot name="text">
						{{ text }}
				</slot>
			</p>
		</div>

	</blockquote>
</template>

<script>
	import src from '../controllers/components/BlockquoteIconCtrl';
	export default src;
</script>
