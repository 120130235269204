<template>

		<section class="section-error">

				<div class="box-error">

					<div class="error-img">
						<img src="@/assets/images/404.svg" alt="404">
					</div>

					<div class="error-messages">
						ERROR
					</div>

					<div class="error-txt">
						Page not found...
					</div>

					<router-link :to="{ name: 'Home' }" class="btn btn-fix-2 btn-green">Back to Main</router-link>

				</div>

		</section>

</template>
